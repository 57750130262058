import { Col, Row } from "react-bootstrap";
import SearchResult from "../SearchResult/SearchResult";
import LoadingSpinnerText from '../LoadingSpinnerText/LoadingSpinnerText';
import { Error } from "../../typeDeclarations/interfaces";
import { chosenMessageStyle } from "../../styles/style";

interface Props {
    documentOptions: any;
    searchErrors: (value: string) => void;
    errorsSelected: any;
    addErrorNotes: any;
    setAddErrorNotes: (notes: string) => void;
    searchText: string;
    followUpDate: string;
    results: any;
    clear: () => void;
    selectError: (error: any) => void;
    removeError: (error: any) => void;
    addError: () => void;
    apixErrors: any;
    docType: string;
    addingError: boolean;
    errorAddedSuccessfully: boolean;
    errorAddFailure: boolean;
}

export default function EnterNewError({
    documentOptions,
    searchErrors,
    errorsSelected,
    addErrorNotes,
    setAddErrorNotes,
    searchText,
    followUpDate,
    results,
    clear,
    selectError,
    removeError,
    addError,
    docType,
    addingError,
    errorAddedSuccessfully,
    errorAddFailure
}: Props) {


    return (
        <div>
            <h4>Enter New Error</h4>

            <Row>
                <Col lg={3}>
                    <label>Document</label>
                </Col>
                <Col>
                    <Row>
                        <Col lg={5}>
                            <input className="form-control" value={docType} name="document" disabled />
                        </Col>
                    </Row>
                    <div className="text-muted font-small"> Options: {documentOptions.map((docType: string, index: number) => <span key={docType}>{docType}{index < documentOptions.length - 1 && ","}</span>)}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="mt-2">
                    <Row>
                        <Col lg={3}>
                            <label>Error</label>
                        </Col>
                        <Col>

                            <div className="input-group mb-3">
                                <input type="search" name="searchText" value={searchText} onChange={e => searchErrors(e.target.value)} className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button className="btn btn-primary" type="button" id="button-addon2" ><i className="bi bi-search" /></button>
                            </div>


                            <div style={{ height: "200px", overflow: "auto" }}>
                                <span className='font-small text-muted'>results: {results ? results.length : ""}</span>

                                {results &&
                                    <>
                                        {results.map((result: any, index: number) => (
                                            <SearchResult
                                                key={index}
                                                result={result}
                                                searchText={searchText}
                                                selectError={selectError}
                                                clear={clear}
                                            />
                                        ))}
                                    </>
                                }

                            </div>

                            <div className="mt-2">
                                <p className="text-muted">Chosen:</p>
                                {errorsSelected.length === 0 && addErrorNotes && <div className="text-danger redBadge rounded p-2">Please select an error from the list above</div>}
                                {errorsSelected.map((result: Error, index: number) => (
                                    <div className={chosenMessageStyle} key={index} >
                                        <div className="d-flex justify-content-between">
                                            <div>{result.itemNumber}. {result.description}</div>
                                            <button className="btn btn-sm btn-secondary" onClick={() => { removeError(result); clear(); }}>X</button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <Row>
                        <Col lg={3}></Col>
                        <Col className="d-flex justify-content-start text-muted">
                            Follow up date: {followUpDate}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <Row>
                        <Col lg={3}>
                            <label>Notes</label>
                        </Col>
                        <Col >
                            <textarea className="form-control" value={addErrorNotes} onChange={e => setAddErrorNotes(e.target.value)} />
                        </Col>
                    </Row>
                </Col>
            </Row>



            <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-outline-primary">Cancel</button>

                <span className="d-inline-block">
                    {addingError ?
                        <button className="btn btn-primary" disabled={addingError}>
                            <LoadingSpinnerText loadingText='adding' spinnerColor='light' />
                        </button>
                        :
                        <button className="btn btn-primary"
                            onClick={addError}
                            disabled={errorsSelected.length === 0 || !addErrorNotes}
                            style={errorsSelected.length === 0 || !addErrorNotes ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                        >
                            Add error
                        </button>
                    }
                </span>
            </div>


            {errorAddFailure &&
                <div className="p-3 redBadge mt-2">Failed to add error</div>
            }

            {errorAddedSuccessfully &&
                <div className="p-3 greenBadge mt-2">
                    Error Added <i className="bi bi-check" />
                </div>
            }

        </div>
    )
}