import { Col, Row } from 'react-bootstrap'

interface Props {
    SelectedEntitySection: any;
    setIsResolved: (isResolved: string) => void;
    isResolved: string;
    CancelSubmitButton: any;
    BackButton: any;
    NextButton: any;


}

export default function StepOne({

    SelectedEntitySection,
    setIsResolved,
    isResolved,
    CancelSubmitButton,
    BackButton,
    NextButton

}: Props) {

    const isNextButtonDisabled = isResolved !== "false" && isResolved !== "true";


    return (
        <div>
            <SelectedEntitySection />


            <div className='my-2'>
                <h6>Is the error resolved?</h6>
            </div>

            <div>
                <Row lg={8}>
                    <Col>
                        <div onClick={() => setIsResolved("true")} className={isResolved === "true" ? "shadow-sm pointer border border-primary rounded p-3" : "lightCardNoHover shadow-sm pointer p-3"}>
                            <input name="isResolved" type="radio" checked={isResolved === "true"} onChange={() => setIsResolved("true")} className="ms-2 form-check-input" /> Resolved
                        </div>
                    </Col>
                    <Col>
                        <div onClick={() => setIsResolved("false")} className={isResolved === "false" ? "shadow-sm pointer border border-primary rounded p-3" : "lightCardNoHover shadow-sm pointer p-3"}>
                            <input name="isResolved" type="radio" checked={isResolved === "false"} onChange={() => setIsResolved("false")} className="ms-2 form-check-input" /> Unresolved
                        </div>
                    </Col>
                </Row>

            </div>

            <div className="d-flex justify-content-between mt-2">
                <div>
                    <CancelSubmitButton />{' '}
                    <BackButton />
                </div>
                <NextButton isDisabled={isNextButtonDisabled} />
            </div>

        </div>
    )
}