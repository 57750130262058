import React from 'react'

type Props = {
    onClick: () => void;
    isSelected: boolean;
    option: any;
}

export default function CannedContactMessage({ onClick, isSelected, option}: Props) {

    const chosenMsgStyle = "card shadow-sm border border-primary pointer mb-1 ps-1";


    return (
        <div className={isSelected ? chosenMsgStyle : "card shadow-sm lightCardNoHover pointer mb-1 ps-1"} key={option.id} onClick={onClick} data-testid="cannedContactMessageCard">
            <span data-testid="cannedMessageNote"><input type="radio" className="form-check-input" checked={isSelected} onChange={e => null}/> {option.note}</span>
        </div>
    )
}