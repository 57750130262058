import { Row, Col } from "react-bootstrap";
import { disabledCardStyle, selectedEntityStyle } from "../../styles/style";
import { ContactEntityEnum, ContactMethodEnum, SentOrReceivedEnum } from "../../typeDeclarations/Enums";
import { DetachmentData, ParentData } from '../../typeDeclarations/interfaces';
import ContactButton from "../ContactButton/ContactButton";
import { SelectContactMethodFunctionInterface } from "../HistoricalErrorsSection/HistoricalErrorsSection";

interface Props {
    isSelected: boolean;
    isDisabled?: boolean;
    handleClick: () => void;
    selectedContactEntity: string;

    selectContactMethod: SelectContactMethodFunctionInterface;
    sentOrReceived: string;
    selectedMethod: string;

    applicantCaseData?: any;
    vendorData?: any;
    parentData?: ParentData;
    isParentDataAvailable?: boolean;
    detachmentData?: DetachmentData;

    contactEntity: string;
}

export default function SelectContactCard({
    isSelected,
    isDisabled,
    handleClick,
    selectedContactEntity,
    selectContactMethod,
    sentOrReceived,
    selectedMethod,


    applicantCaseData,
    vendorData,
    parentData,
    isParentDataAvailable,
    detachmentData,

    contactEntity
}: Props) {

    const receiptText = "I received a message via";
    const senderText = "I sent a message via";



    const senderFunction = (contactMethod: ContactMethodEnum) => {
        selectContactMethod(contactMethod, SentOrReceivedEnum.SENT);
    };

    const receiverFunction = (contactMethod: ContactMethodEnum) => {
        selectContactMethod(contactMethod, SentOrReceivedEnum.RECEIVED)
    };



    // Determine if the contact button is selected or not
    const checkIfSentSelected = (contactMethod: ContactMethodEnum, entity: ContactEntityEnum) => {
        if (contactMethod === selectedMethod
            && sentOrReceived === SentOrReceivedEnum.SENT
            && selectedContactEntity === entity
        ) return true
        else return false
    }

    const checkIfReceivedSelected = (contactMethod: ContactMethodEnum, entity: ContactEntityEnum) => {
        if (contactMethod === selectedMethod
            && sentOrReceived === SentOrReceivedEnum.RECEIVED
            && selectedContactEntity === entity
        ) return true
        else return false
    }



    return (
        <div className={isDisabled? disabledCardStyle : isSelected ? selectedEntityStyle : "card lightCardNoHover pointer py-2"} onClick={handleClick}>

            {contactEntity === "applicant" &&
                <Row>
                    <Col>
                        <div className="ms-4 font-small">
                            <div>Name: {applicantCaseData.applicant.firstName} {applicantCaseData.applicant.lastName}</div>
                            <div>Last 4 SSN: {applicantCaseData.applicant.ssnLast4}</div>
                            <div>DOB: {applicantCaseData.applicant.dateOfBirth}</div>
                            <div>Email: {applicantCaseData.applicant.email}</div>
                        </div>
                    </Col>
                    <Col>
                        {isSelected &&
                            <Row>
                                <div>

                                    <Row>
                                        <Col>
                                            <div>{senderText}</div>


                                            <div className="d-grid gap-2 d-md-block">
                                                <ContactButton
                                                    contactAddress="2159531563"
                                                    contactMethod={ContactMethodEnum.PHONE}
                                                    isSelected={checkIfSentSelected(ContactMethodEnum.PHONE, ContactEntityEnum.APPLICANT)}
                                                    handleClick={() => senderFunction(ContactMethodEnum.PHONE)}
                                                />{' '}
                                                <ContactButton
                                                    contactAddress="xample@gmail.com"
                                                    contactMethod={ContactMethodEnum.EMAIL}
                                                    isSelected={checkIfSentSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.APPLICANT)}
                                                    handleClick={() => senderFunction(ContactMethodEnum.EMAIL)}
                                                />
                                            </div>
                                        </Col>

                                        <Col>
                                            <div>{receiptText}</div>


                                            <div className="d-grid gap-2 d-md-block">
                                                <ContactButton
                                                    contactAddress="2159531563"
                                                    contactMethod={ContactMethodEnum.PHONE}
                                                    isSelected={checkIfReceivedSelected(ContactMethodEnum.PHONE, ContactEntityEnum.APPLICANT)}
                                                    handleClick={() => receiverFunction(ContactMethodEnum.PHONE)}
                                                />{' '}
                                                <ContactButton
                                                    contactAddress="xample@gmail.com"
                                                    contactMethod={ContactMethodEnum.EMAIL}
                                                    isSelected={checkIfReceivedSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.APPLICANT)}
                                                    handleClick={() => receiverFunction(ContactMethodEnum.EMAIL)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                </div>

                            </Row>
                        }
                    </Col>
                </Row>
            }


            {contactEntity === "vendor" &&
                <>
                    <Row>
                        <Col>
                            <div className="ms-4 font-small">
                                <div>Vendor Code: {vendorData.vendorCode}</div>
                                <div>Name: {vendorData.name}</div>
                                <div>Type: {vendorData.examName}</div>
                                <div>Address: {vendorData.streetAddress} {vendorData.city} {vendorData.state} {vendorData.postalCode}</div>
                                <div>Email: {vendorData.emails[0].value}</div>
                                <div>Fax: {vendorData.faxNumbers[0].value}</div>
                            </div>
                        </Col>
                        <Col>
                            {isSelected &&
                                <Row>
                                    <div>
                                        <Row>
                                            <Col>
                                                <div>{senderText}</div>

                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.PHONE, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.EMAIL)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.FAX}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.FAX, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.FAX)}
                                                    />
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.PARCEL}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.PARCEL, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.PARCEL)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div>{receiptText}</div>

                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.PHONE, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.EMAIL)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.FAX}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.FAX, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.FAX)}
                                                    />
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.PARCEL}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.PARCEL, ContactEntityEnum.VENDOR)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.PARCEL)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </Row>
                            }
                        </Col>
                    </Row>

                </>
            }

            {contactEntity === "parent" &&
                <>

                    <Row>
                        <Col>
                            <div className="ms-4 font-small">
                                {isParentDataAvailable ?
                                    <>
                                        <div>Name: {parentData?.parentFirstName} {parentData?.parentLastName}</div>
                                        <div>Email: {parentData?.parentEmail}</div>
                                    </>
                                    :
                                    <div className="text-muted py-3 my-2">No parent data available</div>
                                }
                            </div>
                        </Col>
                        <Col>
                            {isSelected &&
                                <Row>
                                    <div>

                                        <Row>
                                            <Col>
                                                <div>{senderText}</div>


                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.PHONE, ContactEntityEnum.PARENT)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.PARENT)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.EMAIL)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div>{receiptText}</div>


                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.PHONE, ContactEntityEnum.PARENT)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.PARENT)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.EMAIL)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </Row>
                            }
                        </Col>
                    </Row>
                </>
            }

            {contactEntity === "detachment" &&
                <>

                    <Row>
                        <Col>
                            <div className="ms-4 font-small">
                                <div>UnitName: {detachmentData?.unitName}</div>
                                <div>UnitNumber: {detachmentData?.unitNumber}</div>
                                <div>Address: {detachmentData?.address}</div>
                                <div>Email: {detachmentData?.email}</div>
                                <div>Phone: {detachmentData?.phone}</div>
                            </div>
                        </Col>
                        <Col>
                            {isSelected &&
                                <Row>
                                    <div>

                                        <Row>
                                            <Col>
                                                <div>{senderText}</div>


                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.PHONE, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.EMAIL)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.FAX}
                                                        isSelected={checkIfSentSelected(ContactMethodEnum.FAX, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => senderFunction(ContactMethodEnum.FAX)}
                                                    />{' '}
                                                </div>
                                            </Col>

                                            <Col>
                                                <div>{receiptText}</div>


                                                <div className="d-grid gap-2 d-md-block">
                                                    <ContactButton
                                                        contactAddress="2159531563"
                                                        contactMethod={ContactMethodEnum.PHONE}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.PHONE, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.PHONE)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="xample@gmail.com"
                                                        contactMethod={ContactMethodEnum.EMAIL}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.EMAIL, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.EMAIL)}
                                                    />{' '}
                                                    <ContactButton
                                                        contactAddress="fdfsdfs"
                                                        contactMethod={ContactMethodEnum.FAX}
                                                        isSelected={checkIfReceivedSelected(ContactMethodEnum.FAX, ContactEntityEnum.DETACHMENT)}
                                                        handleClick={() => receiverFunction(ContactMethodEnum.FAX)}
                                                    />{' '}
                                                </div>

                                            </Col>
                                        </Row>

                                    </div>
                                </Row>
                            }
                        </Col>
                    </Row>

                </>
            }

        </div>
    )
}