import { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { Buffer } from 'buffer';
import { useParams } from 'react-router-dom';
import { EnvironmentSettings } from '../App';
import API, { VerifyGuidReqBody } from '../utils/API';
import EnterNewError from '../components/EnterNewError/EnterNewError';
import HistoricalErrorsSection from '../components/HistoricalErrorsSection/HistoricalErrorsSection';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { determineProvider, formatContactEntityDetails, formatErrorObject } from '../utils/helperFunctions';
import { ErrorLog, Error, ChooseMessageFunction, DetachmentData, ParentData } from '../typeDeclarations/interfaces';
import { ContactMethodEnum, SentOrReceivedEnum } from '../typeDeclarations/Enums';



interface Props {
    environmentSettings: EnvironmentSettings;
    logger: any;
}




export default function ErrorPage({ environmentSettings, logger }: Props) {
    let { backendApiUrl, showCacheControls } = environmentSettings;

    type UrlParam = {
        verificationCode: string;
        applicantId: string;
        caseExamId: string;
        vendorCode: string;
        userName: string;
        docType: string;
    }

    let { verificationCode, applicantId, caseExamId, vendorCode, userName, docType } = useParams<UrlParam>();


    const [applicantIdDecoded, setApplicantIdDecoded] = useState("");
    const [userNameDecoded, setUserNameDecoded] = useState("");


    const [isCSP, setIsCSP] = useState(false);
    const [detachmentData, setDetachmentData] = useState<DetachmentData>({
        unitName: "",
        unitNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        email: "",
        phone: ""
    });

    const colors = "color: white; background: orange";

    const [applicantCaseData, setApplicantCaseData] = useState<any>();
    const [vendorData, setVendorData] = useState<any>();
    const [parentData, setParentData] = useState<ParentData>({
        parentEmail: "",
        parentFirstName: "",
        parentLastName: "",
        parentMiddleName: "",
        parentRelationship: ""
    })
    const [isParentDataAvailable, setIsParentDataAvailable] = useState(false);

    const [ssn, setSSN] = useState("");
    const documentOptions = ["M (Medical)", " O (Optometric)", " A (Audiological), H (Medical History Survey)"];
    // const caseDueDate = new Date(2022, 4, 27);
    const today = new Date();
    const tomorrow = today.setDate(today.getDate() + 1);
    const tomorrow_as_date = new Date(tomorrow);
    const follow_up_day = tomorrow_as_date.getDate();
    const follow_up_month = tomorrow_as_date.getMonth() + 1;
    const follow_up_year = tomorrow_as_date.getFullYear();
    const followUpDate = `${follow_up_month}/${follow_up_day}/${follow_up_year}`;

    const [searchText, setSearchText] = useState("");
    const [selectedContactEntity, setSelectedContactEntity] = useState("");




    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // =============================================================================
    // State for Entering a new error
    // =============================================================================
    const [addingError, setAddingError] = useState(false);
    const [errorAddedSuccessfully, setErrorAddedSuccessfully] = useState(false);
    const [errorAddFailure, setErrorAddFailure] = useState(false);
    const [addErrorNotes, setAddErrorNotes] = useState("");


    // =============================================================================
    // State for Error Logs 
    // =============================================================================

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const [errorLogs, setErrorLogs] = useState<any>([]);
    const [selectedErrogLogId, setSelectedErrogLogId] = useState(0);
    const [loadingErrorLogs, setLoadingErrorLogs] = useState(false);
    const [areAllErrorsClosed, setAreAllErrorsClosed] = useState(false);

    const [closeAllErrorsNote, setCloseAllErrorsNote] = useState("");
    const [chosenErrorLog, setChosenErrorLog] = useState<any>();
    const [selectedMethod, setSelectedMethod] = useState<ContactMethodEnum | string>("");
    const [sentOrReceived, setSentOrReceived] = useState<SentOrReceivedEnum | string>("");

    const [isCloseAllErrors, setIsCloseAllErrors] = useState(false);
    const [allErrorsClosed, setAllErrorsClosed] = useState(false);
    const [loadingCloseAllErrors, setLoadingCloseAllErrors] = useState(false);

    const [chosenFinalMsg, setChosenFinalMsg] = useState("");

    const [apixErrors, setApixErrors] = useState<any>();
    const [errorsSelected, setErrorsSelected] = useState<Error[]>([]);
    const [results, setResults] = useState<Error[]>(apixErrors);

    const [isResolved, setIsResolved] = useState("");
    const [resolutionNotes, setResolutionNotes] = useState("");
    const [quickErrorOptions, setQuickErrorOptions] = useState<any>();

    const [stepNumber, setStepNumber] = useState(0);



    // ============================================
    // Step functions
    // ============================================
    const goToNextStep = () => setStepNumber(step => step + 1);
    const goBackOneStep = () => {
        setStepNumber(step => step - 1); setResolutionNotes(""); setSubmitError(false); setIsResolved(""); setChosenFinalMsg("");
    }
    const cancelSubmit = () => {
        setStepNumber(0); setChosenErrorLog(undefined); setSelectedContactEntity(""); setIsResolved("");
    };


    // ============================================
    // Buttons
    // ============================================
    interface NextButtonProps {
        isDisabled?: boolean;
    }
    interface BackButtonProps {
        isDisabled?: boolean;
    }

    function NextButton({ isDisabled }: NextButtonProps) {
        return <button className="btn btn-primary" disabled={isDisabled} onClick={goToNextStep}>next</button>
    }

    function BackButton({ isDisabled }: BackButtonProps) {
        return <button className="btn btn-primary" disabled={isDisabled} onClick={goBackOneStep} >back</button>
    }

    function CancelSubmitButton() {
        return <button className="btn btn-outline-primary" onClick={cancelSubmit}>cancel</button>
    }

    const clear = () => {
        setSearchText("");
        setResults(apixErrors);
    }


    // ============================================
    // Add / remove errors (left side of screen)
    // ============================================
    const searchErrors = (searchText: string) => {
        setSearchText(searchText);
        let results = apixErrors.filter((item: any) => item.description.toLowerCase().includes(searchText.toLowerCase()) || item.itemNumber.includes(searchText.toLowerCase()));
        // logger.info("results are: ", results);

        setResults(results);

        if (searchText === "") {
            setResults(apixErrors);
        }
    }

    const selectError = (error: any) => {
        if (errorsSelected.length === 0) {

            if (errorsSelected.includes(error)) {
                let index = errorsSelected.findIndex(item => item === error);
                let newArr = errorsSelected.splice(index, 1);
                setErrorsSelected(newArr);

            } else {
                let newArr = [...errorsSelected, error];
                setErrorsSelected(newArr);
            }
        }
    }

    const removeError = (error: Error) => {
        let newArr = errorsSelected.filter(err => err !== error)
        logger.info("newArr: ", newArr);
        setErrorsSelected(newArr);
    }

    const addError = () => {
        logger.info("errors selected: ", errorsSelected);

        let errorObject: ErrorLog = {
            "caseId": applicantCaseData.case.id,
            "errTypeId": parseInt(errorsSelected[0].id),
            "logDate": new Date().toISOString(),
            "urgent": "no",
            "logByUserName": userNameDecoded || "",
            "note": "string",
            "vendorCode": 529888,
            "provMgr": "",
            "notes": addErrorNotes
        }
        logger.info("error to be created: ", errorObject);


        setAddingError(true);

        API.createNewError(errorObject, backendApiUrl)
            .then(() => {
                logger.info("error added succesfully ✔");
                setAddingError(false);
                setErrorAddedSuccessfully(true);
                setErrorAddFailure(false);
                setErrorsSelected([]);
                setTimeout(() => {
                    setErrorAddedSuccessfully(false)
                }, 3000);

                setLoadingErrorLogs(true);

                const getErrorLogs = () => {

                    if (applicantId) {
                        let id = parseInt(applicantIdDecoded);

                        API.getErrorLogs(id, backendApiUrl)
                            .then(res => {
                                logger.info("b) bakcend get errors: ", res);

                                let logs = res.data;
                                determineIfAllLogsClosed(logs);

                                setErrorLogs(logs);
                                setLoaded(true);
                                setError(false);
                                setLoadingErrorLogs(false);
                            })
                            .catch(err => {
                                logger.info("backend geterrors err: ", err);
                                setLoadingErrorLogs(false);
                                setLoaded(true);
                            })
                    }
                }
                getErrorLogs();

            })
            .catch(err => {
                logger.info("failed to add: ", err);
                setAddingError(false);
                setErrorAddedSuccessfully(false);
                setErrorAddFailure(true);
            })

        setErrorsSelected([]);
        setAddErrorNotes("");
        setChosenErrorLog(undefined);
    }

    const determineIfAllLogsClosed = (logs: any) => {
        let areAllClosed = logs.every((log: any) => log.isClosed === true);
        setAreAllErrorsClosed(areAllClosed);
    }



    // =======================================================================
    // Close single or all errors, or submit responses (right side of screen)
    // =======================================================================
    // The green button
    const closeAllErrors = () => {
        logger.info("close all errors");
        setIsCloseAllErrors(true);
    }
    // The yes button
    const handleCloseAllErrors = () => {

        // NOTE: finish this call later
        setLoadingCloseAllErrors(true);
        let caseId = applicantCaseData.case.id;
        let notes = { notes: closeAllErrorsNote };

        logger.debug("the notes are: ", notes);
        logger.debug("caseId is: ", caseId);

        API.closeAllErrors(backendApiUrl, notes, caseId)
            .then(res => {
                logger.info("closeAllErrors res ✔ : ", res.data);
                setLoadingCloseAllErrors(false);
                setAllErrorsClosed(true);


                if (applicantId) {
                    let id = parseInt(applicantIdDecoded);
                    setLoadingErrorLogs(true);

                    API.getErrorLogs(id, backendApiUrl)
                        .then(res => {
                            logger.info("b) bakcend get errors: ", res);

                            let logs = res.data;
                            determineIfAllLogsClosed(logs);


                            setErrorLogs(res.data);
                            setLoadingErrorLogs(false);
                        })
                        .catch(err => {
                            logger.error("backend geterrors err: ", err);
                            setLoadingErrorLogs(false);
                        })
                }
            })
            .catch(err => {
                setLoadingCloseAllErrors(false);
                setAllErrorsClosed(false);
            })
    }

    const getErrorLogs = () => {

        if (applicantId) {
            let id = parseInt(applicantIdDecoded);
            logger.debug("loading error logs...")
            setLoadingErrorLogs(true);

            API.getErrorLogs(id, backendApiUrl)
                .then(res => {
                    logger.info("error logs are: ", res);

                    setErrorLogs(res.data);
                    determineIfAllLogsClosed(res.data);
                    setLoadingErrorLogs(false);
                })
                .catch(err => {
                    logger.error("get error logs err: ", err);
                    setLoadingErrorLogs(false);
                })
        }
    }

    const submitErrorResponse = () => {
        if (chosenErrorLog && userName !== undefined) {
            let chosenDocumentId = chosenErrorLog.id;
            logger.info("chosenDocumentId: ", chosenDocumentId);
            logger.info("errorsSelected: ", errorsSelected);
            let errorLogId = chosenErrorLog.id;
            logger.info("vendorData is: ", vendorData);
            logger.info("detachmentData is: ", detachmentData);
            logger.info("parentData is: ", parentData);
            let contactEntityInfoDetails = formatContactEntityDetails(selectedContactEntity, applicantCaseData, vendorData, detachmentData, parentData);
            logger.info("contactEntityInfoDetails: ", contactEntityInfoDetails);
            let errorObject = formatErrorObject(selectedContactEntity, selectedMethod, sentOrReceived, isResolved, contactEntityInfoDetails, chosenFinalMsg, resolutionNotes, errorLogId, userNameDecoded);
            logger.info("error response to about be created: ", errorObject);

            setIsSubmitting(true);

            API.createErrorResponse(errorObject, backendApiUrl)
                .then(res => {
                    logger.info("error response created successfully: ✔", res);

                    // then close the single error, if resolved is selected. 
                    if (isResolved === "true") {
                        logger.debug("isResolved is true")

                        let reqBody = {
                            "errorLogIds": [
                                errorLogId
                            ],
                            "notes": "string"
                        }
                        let caseId = applicantCaseData.case.id;
                        API.closeError(backendApiUrl, reqBody, caseId)
                            .then(res => {
                                logger.info("close error res: ", res);
                                logger.info("✔ error succcessfuly closed");
                                logger.info("error log(s) closed")

                                goToNextStep();
                                setIsSubmitting(false);
                                setIsSubmitted(true);
                                setChosenErrorLog(undefined);
                                setSelectedErrogLogId(0);
                                setChosenErrorLog(undefined);

                                setStepNumber(0);

                                getErrorLogs()
                                setSubmitError(false);
                            })
                            .catch(err => {
                                logger.error(err);
                                setIsSubmitting(false);
                                setIsSubmitted(false);
                            })
                    } else {
                        logger.debug("isResolved is false")

                        goToNextStep();
                        setIsSubmitting(false);
                        setIsSubmitted(true);
                        setChosenErrorLog(undefined);

                        getErrorLogs()
                    }

                })
                .catch(err => {
                    logger.error(err);
                    setIsSubmitting(false);
                    setIsSubmitted(false);
                    setSubmitError(true);
                })
        }
    }

    const submitAnotherResponse = () => {
        setIsSubmitted(false);
        setCloseAllErrorsNote("");
        setIsResolved("");
        setResolutionNotes("");
        setSelectedContactEntity("");
        setSelectedMethod("");
        setSentOrReceived("");
        setChosenFinalMsg("");
        setStepNumber(0);
    }

    const chooseMessage: ChooseMessageFunction = (documentName, error) => {
        logger.info(documentName, error);
    }

    const chooseErrorLog = (errorLog: any) => {
        setChosenErrorLog(errorLog);
        selectErrogLogId(errorLog)
    }

    const selectErrogLogId = (item: any) => setSelectedErrogLogId(item.id);


    // =============================================================
    // Caching
    // =============================================================

    const [cacheKeys, setCacheKeys] = useState<string[]>([]);
    const [newCacheKey, setNewCacheKey] = useState("");

    const removeCacheKey = (key: string) => {
        let filtered = cacheKeys.filter(item => item !== key);
        setCacheKeys(filtered);
    }

    const [isClearingCache, setIsClearingCache] = useState(false);
    const [cacheCleared, setCacheCleared] = useState(false);
    const [cacheClearError, setCacheClearError] = useState(false);

    const clearCache = () => {

        setIsClearingCache(true);
        API.clearCache(backendApiUrl, cacheKeys)
            .then(res => {
                logger.info("clear cache res: ", res.data);
                setIsClearingCache(false);
                setCacheCleared(true);
                setCacheClearError(false);
                setCacheKeys([]);
            })
            .catch(err => {
                logger.error("clear cache err: ", err);
                setIsClearingCache(false);
                setCacheCleared(false);
                setCacheClearError(true);
            })
    }

    const addCacheKey = () => {
        setCacheKeys([...cacheKeys, newCacheKey]);
        setNewCacheKey("");
    }


    // load data for the page
    useEffect(() => {

        setLoading(true);

        // Url params, before they are decoded
        logger.info({ verificationCode, applicantId, caseExamId, vendorCode, userName, docType });

        // take the url param as a base64EncodedString and decode it into plain text
        // the verificationCode stays as a base64 string. The rest (applicantId, caseExamId, vendorCode, userName, and docType are all converted from base64 into plain text strings)

        let applicantIdDecoded = (Buffer.from(applicantId as string, 'base64')).toString();
        let caseExamIdDecoded = (Buffer.from(caseExamId as string, 'base64')).toString();
        let vendorCodeDecoded = (Buffer.from(vendorCode as string, 'base64')).toString();
        let userNameDecoded = (Buffer.from(userName as string, 'base64')).toString();

        // the decoded applicant id & userName are needed outside the scope of this useEffect. Store each as a state variable for use in the other functions.
        setApplicantIdDecoded(applicantIdDecoded);
        setUserNameDecoded(userNameDecoded);

        // Url params, after they are decoded
        logger.info({ applicantIdDecoded, caseExamIdDecoded, vendorCodeDecoded, userNameDecoded });


        let res = API.getQuickErrorOptions();
        logger.info("%cres: ", colors, res);
        setQuickErrorOptions(res);

        
        const getToken = async () => {
            try {
                logger.info("getting token...")
                logger.info("backendApiUrl is: ", backendApiUrl);
                let res = await API.testBackendLogin(backendApiUrl);
                logger.info("token response: ", res.data);
                let result = res.data;
                return result;
            } catch (err) {
                logger.error(err);
                setLoading(false);
                setError(true);
                setErrorMsg('Token not found');
            }
        }

        getToken()
            .then(() => {
                logger.info("login successful ✔");


                if (verificationCode) {
                    let reqBody: VerifyGuidReqBody = {
                        guid: verificationCode,
                        idValue: caseExamIdDecoded
                    }

                    logger.info("guid verification req body is: ", reqBody);

                    API.verifyGuid(backendApiUrl, reqBody)
                        .then(res => {
                            logger.info("verification res success: ", res);


                            if (docType) {

                                API.getDocumentErrors(docType, backendApiUrl)
                                    .then(res => {
                                        logger.info("a) document error options: ", res);
                                        let options = res.data;
                                        if (options.length === 0) {
                                            setError(true);
                                            setErrorMsg('No document error options found');
                                            setLoaded(true);
                                            setLoading(false);
                                        } else {
                                            setApixErrors(res.data);
                                            setResults(res.data);
                                            getErrorLogs(); // once resolved
                                        }
                                    })
                                    .catch(err => {
                                        logger.error("document error options err: ", err);
                                        setLoading(false);
                                        setError(true);
                                        setErrorMsg('Error loading document errors');
                                        setLoaded(true);
                                    })
                            }

                            const getErrorLogs = () => {

                                if (applicantId) {
                                    let id = parseInt(applicantIdDecoded);

                                    API.getErrorLogs(id, backendApiUrl)
                                        .then(res => {
                                            logger.info("b) error logs: ", res);

                                            let logs = res.data;
                                            determineIfAllLogsClosed(logs);

                                            setErrorLogs(res.data);
                                            getApplicantData() // once resolved
                                        })
                                        .catch(err => {
                                            logger.error("get error logs err: ", err);
                                            setLoading(false);
                                            setError(true);
                                            setErrorMsg('Error retrieving error logs');
                                            setLoaded(true);
                                        })
                                }
                            }

                            const getApplicantData = () => {
                                if (applicantId) {
                                    let ApplicantId = parseInt(applicantIdDecoded);


                                    API.getApplicantData(ApplicantId, backendApiUrl)
                                        .then(res => {
                                            logger.info("%capplicant data res: ", colors, res.data);

                                            setSSN(res.data.applicant.ssnLast4); // NOTE: check this
                                            let isCSP = res.data.program.programName === "DODCSP";

                                            // =====================
                                            // CSP Data
                                            // =====================
                                            // let isCSP = true;
                                            if (isCSP) {

                                                if (res.data.unit) {
                                                    let unit = res.data.unit;
                                                    let phoneNumber = unit.phoneNumbers && unit.phoneNumbers.length > 0 ? unit.phoneNumbers[0].value : "";
                                                    let email = unit.emails && unit.emails.length > 0 ? unit.emails[0].value : "";
                                                    setDetachmentData({
                                                        unitName: unit.unitName || "",
                                                        unitNumber: unit.unitNumber || "",
                                                        address: unit.streetAdress || "",
                                                        city: unit.city || "",
                                                        state: unit.state || "",
                                                        zipCode: unit.zipCode || "",
                                                        email: email,
                                                        phone: phoneNumber
                                                    })
                                                }
                                            }
                                            setIsCSP(isCSP);

                                            // =========================
                                            // Parent Data
                                            // =========================
                                            let applicantProfile = res.data.applicantProfile;
                                            if (applicantProfile) {
                                                let isParentDataAvailable = applicantProfile.parentEmail !== undefined && applicantProfile.parentEmail !== null;
                                                logger.info("isParentDataAvailable", isParentDataAvailable);

                                                setParentData({
                                                    parentEmail: applicantProfile.parentEmail || "",
                                                    parentFirstName: applicantProfile.parentFirstName || "",
                                                    parentLastName: applicantProfile.parentLastName || "",
                                                    parentMiddleName: applicantProfile.parentMiddleName || "",
                                                    parentRelationship: applicantProfile.parentRelationship || ""
                                                });

                                                setIsParentDataAvailable(isParentDataAvailable)
                                            } else {
                                                logger.info("no applicant profile")
                                                setParentData({
                                                    parentEmail: "",
                                                    parentFirstName: "",
                                                    parentLastName: "",
                                                    parentMiddleName: "",
                                                    parentRelationship: ""
                                                });
                                                setIsParentDataAvailable(false);
                                            }




                                            setApplicantCaseData(res.data);

                                            if (vendorCode) {
                                                let selectedProvider = determineProvider(res.data.providers, parseInt(vendorCodeDecoded));
                                                logger.info("selected provider: ", selectedProvider);
                                                if (selectedProvider === undefined) {
                                                    setError(true);
                                                    setErrorMsg('No provider found')
                                                    setLoading(false);
                                                    setLoaded(true);
                                                    return;
                                                } else {
                                                    setVendorData(selectedProvider);

                                                    setError(false);
                                                    setLoading(false);
                                                    setLoaded(true);


                                                }
                                            }

                                        })
                                        .catch(err => {
                                            logger.error("applicant data err: ", err);
                                            setLoading(false);
                                            setError(true);
                                            setErrorMsg('Error retrieving applicant data');
                                            setLoaded(true);
                                        })
                                }
                            }
                        })
                        .catch(err => {
                            logger.info("verification res err: ", err);
                            logger.error(err);
                            setError(true);
                            setErrorMsg('Verification error')
                            setLoading(false);
                            setLoaded(true);
                        })



                } else {
                    // if no verification code:
                    setError(true);
                    setErrorMsg('No verification code');
                    setLoading(false);
                    setLoaded(true);
                }

            })
            .catch(err => {
                logger.info(err);
                setErrorMsg('Error getting token')
                setLoading(false);
            })
    }, [verificationCode, applicantId, caseExamId, vendorCode, userName, docType, backendApiUrl, logger]);



    function SelectedEntitySection() {
        return (
            <>
                <h6>Select contact</h6>
                {selectedContactEntity === "applicant" &&
                    <div className="card lightCardNoHover">
                        <div className="ms-4 font-small">
                            <div>Name: {applicantCaseData.applicant.firstName} {applicantCaseData.applicant.lastName}</div>
                            <div>Last 4 SSN: {applicantCaseData.applicant.ssnLast4}</div>
                            <div>DOB: {applicantCaseData.applicant.dateOfBirth}</div>
                            <div>Email: {applicantCaseData.applicant.email}</div>
                        </div>
                    </div>
                }

                {selectedContactEntity === "vendor" &&
                    <div className="card lightCardNoHover">
                        <div className="ms-4 font-small">
                            <div>VendorCode: {vendorData.vendorCode}</div>
                            <div>VendorName: {vendorData.name}</div>
                            <div>VendorType: {vendorData.examName}</div>
                            <div>Address: {vendorData.streetAddress} {vendorData.city} {vendorData.state} {vendorData.postalcode}</div>
                            <div>Email: {vendorData.emails[0].value}</div>
                            <div>Fax: {vendorData.faxNumbers[0].value}</div>
                        </div>
                    </div>
                }

                {selectedContactEntity === "parent" &&
                    <div className="card lightCardNoHover">
                        <div className="ms-4 font-small">
                            {isParentDataAvailable ?
                                <>
                                    <div>Name: {parentData.parentFirstName} {parentData.parentLastName}</div>
                                    <div>Email: {parentData.parentEmail}</div>
                                </>
                                :
                                <div className="text-muted py-2">No parent data available</div>
                            }
                        </div>
                    </div>
                }

                {selectedContactEntity === "detachment" &&
                    <div className="card lightCardNoHover">
                        <div className="ms-4 font-small">
                            <div>UnitName: {detachmentData.unitName}</div>
                            <div>UnitNumber: {detachmentData.unitNumber}</div>
                            <div>Address: {detachmentData.address}</div>
                            <div>Email: {detachmentData.email}</div>
                            <div>Phone: {detachmentData.phone} </div>
                        </div>
                    </div>
                }
            </>
        )
    }



    return (
        <div>
            <Container fluid>


                <div style={{ display: showCacheControls }}>
                    {cacheKeys.map((item: string, index: number) => (
                        <div key={index} className="d-flex justify-content-between mb-2" style={{ maxWidth: "200px" }}>
                            <span>{item}</span>
                            <button onClick={() => removeCacheKey(item)}>remove</button>
                        </div>
                    ))}

                    <input value={newCacheKey} onChange={e => setNewCacheKey(e.target.value)} />
                    <button onClick={addCacheKey}>Add key</button>

                    <div className="mt-2">
                        <button onClick={clearCache} disabled={isClearingCache || cacheKeys.length < 1}> {isClearingCache ? "clearing..." : "clear selected cache"}</button>
                    </div>

                    {cacheCleared && "Succesfully Cleared ✔"}
                    {cacheClearError && <div className="p-2 redBadge">Cache clearing error</div>}

                </div>


                {error && <div className="p-3 redBadge">Error{errorMsg && `. ${errorMsg}.`}</div>}
                {loading && <LoadingScreen />}
                {loaded && !error && !loading &&
                    <Row>
                        <Col lg={12} className="p-3">

                            <Row>
                                <Col lg={2}>
                                    <h1>Errors</h1>
                                </Col>
                                <Col lg={3}>
                                    <Row>
                                        <Col>
                                            <p className="mb-0">SSN: ***-**-{ssn}</p>
                                            {/* <p className="mb-0">Case due: {caseDueDate.getMonth() + 1}/{caseDueDate.getDate()}/{caseDueDate.getFullYear()}</p> */}
                                            <p>Follow up date: {followUpDate}</p>
                                        </Col>
                                        <Col>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>



                            <Row className="mt-4">

                                <Col>
                                    <EnterNewError
                                        documentOptions={documentOptions}
                                        searchErrors={searchErrors}
                                        errorsSelected={errorsSelected}
                                        addErrorNotes={addErrorNotes}
                                        setAddErrorNotes={setAddErrorNotes}
                                        searchText={searchText}
                                        followUpDate={followUpDate}
                                        results={results}
                                        clear={clear}
                                        selectError={selectError}
                                        removeError={removeError}
                                        addError={addError}
                                        apixErrors={apixErrors}
                                        docType={docType || ""}
                                        addingError={addingError}
                                        errorAddedSuccessfully={errorAddedSuccessfully}
                                        errorAddFailure={errorAddFailure}
                                    />
                                </Col>

                                <Col>
                                    <>
                                        {loadingErrorLogs ? <LoadingScreen /> :

                                            <HistoricalErrorsSection
                                                errorLogs={errorLogs}
                                                chooseMessage={chooseMessage}
                                                chooseErrorLog={chooseErrorLog}
                                                chosenErrorLog={chosenErrorLog}

                                                stepNumber={stepNumber}
                                                isSubmitted={isSubmitted}

                                                selectedContactEntity={selectedContactEntity}

                                                applicantCaseData={applicantCaseData}
                                                vendorData={vendorData}
                                                parentData={parentData}
                                                isParentDataAvailable={isParentDataAvailable}
                                                detachmentData={detachmentData}

                                                NextButton={NextButton}
                                                BackButton={BackButton}
                                                CancelSubmitButton={CancelSubmitButton}
                                                SelectedEntitySection={SelectedEntitySection}
                                                setSelectedContactEntity={setSelectedContactEntity}

                                                setIsResolved={setIsResolved}
                                                isResolved={isResolved}

                                                resolutionNotes={resolutionNotes}
                                                setResolutionNotes={setResolutionNotes}
                                                isSubmitting={isSubmitting}
                                                submitErrorResponse={submitErrorResponse}
                                                submitAnotherResponse={submitAnotherResponse}

                                                allErrorsClosed={allErrorsClosed}
                                                areAllErrorsClosed={areAllErrorsClosed}
                                                isCloseAllErrors={isCloseAllErrors}
                                                closeAllErrors={closeAllErrors}
                                                setIsCloseAllErrors={setIsCloseAllErrors}

                                                closeAllErrorsNote={closeAllErrorsNote}
                                                setCloseAllErrorsNote={setCloseAllErrorsNote}

                                                handleCloseAllErrors={handleCloseAllErrors}
                                                loadingCloseAllErrors={loadingCloseAllErrors}

                                                quickErrorOptions={quickErrorOptions}

                                                chosenFinalMsg={chosenFinalMsg}
                                                setChosenFinalMsg={setChosenFinalMsg}

                                                isCSP={isCSP}

                                                selectedErrogLogId={selectedErrogLogId}

                                                selectedMethod={selectedMethod}
                                                setSelectedMethod={setSelectedMethod}
                                                sentOrReceived={sentOrReceived}
                                                setSentOrReceived={setSentOrReceived}

                                                submitError={submitError}
                                            />
                                        }

                                        {/* <div className="bg-info p-3">
                                            <pre>selectedMethod: {selectedMethod}</pre>
                                            <pre>sentOrReceived: {sentOrReceived}</pre>
                                            <pre>chosenFinalMsg: {chosenFinalMsg}</pre>
                                            <pre>isResolved: {isResolved}</pre>
                                        </div> */}
                                    </>
                                </Col>

                            </Row>

                        </Col>
                    </Row>

                }
            </Container >

        </div >
    )

}