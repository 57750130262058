const quickErrorOptions =
{
    applicantOptions: [
        {
            id: "1",
            note: "No answer from the applicant"
        },
        {
            id: "2",
            note: "Left message"
        },
        {
            id: "3",
            note: "The contact has agreed to complete this issue"
        },
        {
            id: "4",
            note: "Left message with person other than the contact"
        },
        {
            id: "5",
            note: "Applicant needs to return to Provider"
        },

    ],
    vendorOptions: [
        {
            id: "1",
            note: "No answer from the provider's office"
        },
        {
            id: "2",
            note: "Left message to provider"
        },
        {
            id: "4",
            note: "The contact has agreed to complete this issue"
        },
    ],
    parentOptions: [
        {
            id: "1",
            note: "No answer from the parent"
        },
        {
            id: "2",
            note: "Left message to parent"
        }
    ],
    detachmentOptions: [
        {
            id: "1",
            note: "Wrong paperwork"
        },
        {
            id: "2",
            note: "Wrong providers"
        },
        {
            id: "3",
            note: "Wrong information entered into system"
        },
    ]
};

export default quickErrorOptions;