
interface Props {
    onChange: () => void;
    checked: boolean;
    disabled: boolean;
}

export default function ErrorLogRadioButton({ onChange, checked, disabled }: Props) {
    return (
        <>
            <input type="radio"
                data-testid="errorLogRadioBtn"
                className="me-1 form-check-input"
                onChange={onChange}
                name="errorSelection"
                checked={checked}
                disabled={disabled}
            />
        </>
    )
}