import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import NoMatch from './pages/NoMatch';
import API from "./utils/API";
import './App.css';

const pino = require('pino');


export interface EnvironmentSettings {
  backendApiUrl: string;
  logLevel: string;
  showCacheControls: string;
}

function App() {

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [logger, setLogger] = useState<any>();


  const [environmentSettings, setEnvironmentSettings] = useState<EnvironmentSettings>({
    backendApiUrl: "",
    logLevel: "",
    showCacheControls: ""
  })

  const [settings, setSettings] = useState<any>({
    headerColor: "green",
    headerTitleColor: "blue",
    headerDisplayProperty: "none"
  })

  useEffect(() => {

    API.getEnvironment()
      .then(res => {
        setEnvironmentSettings(res.data);
        let pinoLogger = pino({
          level: res.data.logLevel || "silent"
        })
        setLogger(pinoLogger);
        let logLevel = res.data.logLevel;
        if (logLevel !== "silent" && logLevel !== "") {
          console.log("azure environment is: ", res.data);
        }


        API.getSettings()
          .then(res => {
            if (logLevel !== "silent" && logLevel !== "") {
              console.log("settings are: ", res.data);
            }
            setSettings(res.data);
            setError(false);
            setLoaded(true);
          })
          .catch(err => {
            console.log(err);
            setError(true);
            setLoaded(true);
          })

      })
      .catch(err => {
        setError(true);
        setLoaded(true);
        console.log("azure environment data not found: ", err);
      })
  }, []);


  return (
    <div>

      {error &&
        <div className="p-3 redBadge">
          Error
        </div>
      }


      <div style={{ backgroundColor: settings.headerColor, display: settings.headerDisplayProperty }} className="header">
        <h1 style={{ color: settings.headerTitleColor }}>
          Quick Errors
        </h1>
      </div>


      <>
        <Routes>
          {/* these parameters are base 64 encoded: verificationCode, applicantId, caseExamId, vendorCode, userName */}
          <Route path="/:verificationCode/:applicantId/:caseExamId/:vendorCode/:userName/:docType" element={loaded && <ErrorPage environmentSettings={environmentSettings} logger={logger} />} />
          {/* If any other url is requested send no match page */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </>


    </div>
  );
}

export default App;
