import { CloseStatusEnum } from "../../typeDeclarations/Enums";
import { ChooseMessageFunction } from "../../typeDeclarations/interfaces";
import { handleFormatDate } from "../../utils/helperFunctions";

interface Props {
    chooseMessage: ChooseMessageFunction;
    errorResponse: any;
    errorLog: any
    isErrorClosed: boolean;
}


export default function HistoricalErrorsInternalTableRow({ chooseMessage, errorResponse, errorLog, isErrorClosed }: Props) {


    const isClosed = errorResponse.closeStatus === CloseStatusEnum.CLOSED;

    const date = handleFormatDate(new Date(errorResponse.dateResponse));


    return (
        <tr
            key={errorResponse.id}
            onClick={() => { !isErrorClosed && chooseMessage(errorLog.documentName, errorResponse) }}
            className={isClosed ? "greyBackground" : ""}
            data-testid="historicalErrorsInternalTableRow"
        >
            <td>{errorResponse.id}</td>
            {/* <td>{errorResponse.isClosed} </td> */}
            <td>{errorResponse.userNameResponder}</td>
            <td>{date}</td>
            <td>{errorResponse.response}</td>
            <td>{errorResponse.responseNote}</td>
        </tr>
    )
}