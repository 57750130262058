import { useState } from 'react';
import ErrorLogRadioButton from '../ErrorLogRadioButton/ErrorLogRadioButton';
import ErrorLogTableTitleRow from '../ErrorLogTableTitleRow/ErrorLogTableTitleRow';
import ToggleRowShowButton from '../ToggleShowRadioButton/ToggleRowShowButton';
import { ChooseErrorLogFunction, ChooseMessageFunction } from '../../typeDeclarations/interfaces';
import HistoricalErrorsInternalTableRow from '../HistoricalErrorsInnerTableRow/HistoricalErrorsInternalTableRow';

interface Props {
    errorLog: any;
    chooseMessage: ChooseMessageFunction;
    chosenErrorLog: any;
    chooseErrorLog: ChooseErrorLogFunction;
}

export default function ErrorLogTable({ errorLog, chooseMessage, chosenErrorLog, chooseErrorLog }: Props) {


    const [showRow, setShowRow] = useState(true);
    const toggleShowRow = () => setShowRow(!showRow);

    // console.log("%cchosenErrorLog: ", "color: white; background: blue", chosenErrorLog);


    let isErrorClosed = errorLog.isClosed;
    let isSelected = chosenErrorLog !== undefined && chosenErrorLog?.id && errorLog.id === chosenErrorLog.id && !isErrorClosed;
    // let isErrorClosed = errorLog.errors[errorLog.errors.length - 1].closeStatus === CloseStatusEnum.CLOSED;



    const hasErrorResponses = errorLog.errorResponses !== null;



    return (
        <>
            <table className="table caption-top table-sm">
                <caption >
                    <div className="d-flex justify-content-between">
                        <div className="me-2">

                            <ErrorLogRadioButton
                                onChange={() => { !isErrorClosed && chooseErrorLog(errorLog) }}
                                checked={isSelected}
                                disabled={isErrorClosed}
                            />

                            <ErrorLogTableTitleRow
                                onClick={isErrorClosed ? () => null : () => chooseErrorLog(errorLog)}
                                errorLog={errorLog}
                                disabled={isErrorClosed}
                            />

                        </div>

                        <div>
                            <ToggleRowShowButton toggleShowRow={toggleShowRow} showRow={showRow} />
                        </div>
                    </div>

                    <div>
                        <span>
                            <span className="badge blueBadge"> responses: {errorLog.errorResponses ? errorLog.errorResponses.length : "0"}</span> {isErrorClosed ? <span className="badge lightGreyBadge">closed</span> : <span className="badge blueBadge">open</span>}
                        </span>
                    </div>

                    <div>
                        Logged by: {`${errorLog.userNameLoggedBy}`}
                    </div>

                    <div>
                        Note: {errorLog.notes}
                    </div>

                </caption>
                {(showRow || isSelected) &&
                    <>
                        <thead>
                            <tr>
                                <th># {errorLog.id}</th>
                                {/* <th>Opened / closed</th> */}
                                <th>Responder</th>
                                <th>Date</th>
                                <th>Error</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>

                            {hasErrorResponses ?
                                <>
                                    {errorLog.errorResponses.map((errorResponse: any) => (
                                        <HistoricalErrorsInternalTableRow
                                            key={errorResponse.id}
                                            chooseMessage={chooseMessage}
                                            errorLog={errorLog}
                                            errorResponse={errorResponse}
                                            isErrorClosed={isErrorClosed}
                                        />
                                    ))}
                                </>
                                :
                                <tr className="veryLightGrey font-small">
                                    <td colSpan={6}>no responses</td>
                                </tr>
                            }

                        </tbody>
                    </>
                }
            </table>
        </>
    )
}