import { Col, Row } from "react-bootstrap";
import CloseAllErrorsSection from "../CloseAllErrorsSection/CloseAllErrorsSection";
import ContactStep from "../ContactStep/ContactStep";
import ErrorLogTables from "../ErrorLogTables/ErrorLogTables";
import StepZero from '../StepZero/StepZero';
import StepOne from '../StepOne/StepOne';
import StepTwo from "../StepTwo/StepTwo";
import StepThree from "../StepThree/StepThree";
import CannedContactMessage from '../CannedContactMessage/CannedContactMessage';
import CloseAllErrorsButton from '../CloseAllErrorsButton/CloseAllErrorsButton';
import { ContactEntityEnum, ContactMethodEnum, SentOrReceivedEnum } from '../../typeDeclarations/Enums';
import { ParentData } from "../../typeDeclarations/interfaces";

export interface SelectContactMethodFunctionInterface {
    (arg: ContactMethodEnum, arg2: SentOrReceivedEnum): void;
}



interface Props {
    errorLogs: any;
    chooseMessage: any;
    chooseErrorLog: any;
    chosenErrorLog: any;

    stepNumber: number;
    isSubmitted: boolean;
    selectedContactEntity: any;

    setSelectedContactEntity: (a: string) => void;

    applicantCaseData: any;
    vendorData: any;
    parentData: ParentData;
    isParentDataAvailable: boolean;
    detachmentData: any;

    NextButton: any;
    BackButton: any;
    CancelSubmitButton: any;
    SelectedEntitySection: any;

    setIsResolved: (a: string) => void;
    isResolved: string;
    resolutionNotes: string;
    setResolutionNotes: (a: string) => void;

    isSubmitting: boolean;
    submitErrorResponse: () => void;
    submitAnotherResponse: () => void;

    allErrorsClosed: boolean;
    closeAllErrors: () => void;
    isCloseAllErrors: boolean;
    setIsCloseAllErrors: (isCloseAllErrors: boolean) => void;
    loadingCloseAllErrors: boolean;
    areAllErrorsClosed: boolean;
    closeAllErrorsNote: string;

    setCloseAllErrorsNote: (note: string) => void;
    handleCloseAllErrors: () => void;

    quickErrorOptions: any;
    chosenFinalMsg: string;
    setChosenFinalMsg: (msg: string) => void;
    isCSP: boolean;
    selectedErrogLogId: number;

    selectedMethod: ContactMethodEnum | string,
    setSelectedMethod: (method: ContactMethodEnum | string) => void;
    sentOrReceived: SentOrReceivedEnum | string;
    setSentOrReceived: (state: SentOrReceivedEnum | string) => void;
    submitError: boolean;
}

export default function HistoricalErrorsSection({
    errorLogs,
    chooseMessage,
    chooseErrorLog,
    chosenErrorLog,

    stepNumber,
    isSubmitted,
    selectedContactEntity,

    setSelectedContactEntity,
    applicantCaseData,
    vendorData,
    parentData,
    isParentDataAvailable,
    detachmentData,

    NextButton,
    BackButton,
    CancelSubmitButton,
    SelectedEntitySection,

    setIsResolved,
    isResolved,

    resolutionNotes,
    setResolutionNotes,
    isSubmitting,
    submitErrorResponse,
    submitAnotherResponse,

    allErrorsClosed,
    closeAllErrors,
    isCloseAllErrors,
    setIsCloseAllErrors,
    loadingCloseAllErrors,
    areAllErrorsClosed,

    closeAllErrorsNote,
    setCloseAllErrorsNote,

    handleCloseAllErrors,
    quickErrorOptions,

    chosenFinalMsg,
    setChosenFinalMsg,
    isCSP,
    selectedErrogLogId,

    selectedMethod,
    setSelectedMethod,
    sentOrReceived,
    setSentOrReceived,
    submitError
}: Props) {

    const errorLogIsSelected = selectedErrogLogId !== 0 && chosenErrorLog !== undefined;



    function toggleSelectMsg(msg: string) {
        if (msg === chosenFinalMsg) {
            setChosenFinalMsg(""); setResolutionNotes("");
        } else {
            setChosenFinalMsg(msg);
        }
    }

    // Formats the canned messages
    function DisplayCannedContactMsgs() {
        let options;

        if (selectedContactEntity === ContactEntityEnum.APPLICANT) {
            options = quickErrorOptions.applicantOptions;
        } else if (selectedContactEntity === ContactEntityEnum.VENDOR) {
            options = quickErrorOptions.vendorOptions;
        } else if (selectedContactEntity === ContactEntityEnum.PARENT) {
            options = quickErrorOptions.parentOptions;
        } else if (selectedContactEntity === ContactEntityEnum.DETACHMENT) {
            options = quickErrorOptions.detachmentOptions;
        }

        return (
            <>
                <h6>Choose a message:</h6>
                {options.map((option: any, index: number) => (
                    <CannedContactMessage key={index} onClick={() => toggleSelectMsg(option.note)} option={option} isSelected={option.note === chosenFinalMsg} />
                ))}
            </>
        )
    }


    // Selects a contact method (phone, fax, or email)
    const selectContactMethod = (method: ContactMethodEnum, sentOrReceived: SentOrReceivedEnum) => {
        // console.log("selecting contact method")
        setSentOrReceived(sentOrReceived);
        setSelectedMethod(method);
    }

    const clearSelectedMethod = () => setSelectedMethod("");



    return (
        <div>
            <h4>Historical Errors</h4>
            <p className='text-muted'>Choose an error to respond to:</p>

            <div>
                <ErrorLogTables
                    errorLogs={errorLogs}
                    chooseMessage={chooseMessage}
                    chooseErrorLog={chooseErrorLog}
                    chosenErrorLog={chosenErrorLog}
                />
            </div>


            {errorLogIsSelected &&
                <>
                    <Row>
                        <Col>
                            {/* Step number */}
                            <Row className="mt-2">
                                <Col className="text-center">
                                    <ContactStep isComplete={stepNumber >= 1} stepName="Select Contact" stepNumber="1" />
                                </Col>

                                <Col className="text-center">
                                    <ContactStep isComplete={stepNumber >= 2} stepName="Resolved?" stepNumber="2" />
                                </Col>

                                <Col className="text-center">
                                    <ContactStep isComplete={stepNumber >= 3} stepName="Submit" stepNumber="3" />
                                </Col>
                            </Row>



                            {/* =========================================== */}
                            {/* Who did you contact / who contacted you*/}
                            {/* =========================================== */}
                            {stepNumber === 0 &&
                                <StepZero
                                    selectedContactEntity={selectedContactEntity}
                                    setSelectedContactEntity={setSelectedContactEntity}
                                    applicantCaseData={applicantCaseData}
                                    vendorData={vendorData}
                                    parentData={parentData}
                                    isParentDataAvailable={isParentDataAvailable}
                                    detachmentData={detachmentData}

                                    NextButton={NextButton}
                                    chosenErrorLog={chosenErrorLog}
                                    CancelSubmitButton={CancelSubmitButton}

                                    selectContactMethod={selectContactMethod}
                                    selectedMethod={selectedMethod}
                                    clearSelectedMethod={clearSelectedMethod}

                                    sentOrReceived={sentOrReceived}
                                    isCSP={isCSP}
                                />
                            }

                            {/* =========================================== */}
                            {/* Is the error resolved or not */}
                            {/* =========================================== */}
                            {stepNumber === 1 &&
                                <StepOne
                                    SelectedEntitySection={SelectedEntitySection}
                                    setIsResolved={setIsResolved}
                                    isResolved={isResolved}
                                    CancelSubmitButton={CancelSubmitButton}
                                    BackButton={BackButton}
                                    NextButton={NextButton}
                                />
                            }
                            {/* =========================================== */}
                            {/* Submit response section */}
                            {/* =========================================== */}
                            {stepNumber === 2 &&
                                <StepTwo
                                    SelectedEntitySection={SelectedEntitySection}
                                    isResolved={isResolved}
                                    BackButton={BackButton}
                                    isSubmitted={isSubmitted}
                                    isSubmitting={isSubmitting}
                                    resolutionNotes={resolutionNotes}
                                    setResolutionNotes={setResolutionNotes}
                                    submitErrorResponse={submitErrorResponse}
                                    DisplayCannedContactMsgs={DisplayCannedContactMsgs}
                                    submitAnotherResponse={submitAnotherResponse}
                                    chosenFinalMsg={chosenFinalMsg}
                                    submitError={submitError}
                                />
                            }
                            {/* =========================================== */}
                            {/* Post response submission */}
                            {/* =========================================== */}
                            {stepNumber === 3 &&
                                <StepThree
                                    isSubmitted={isSubmitted}
                                    submitErrorResponse={submitErrorResponse}
                                    isSubmitting={isSubmitting}
                                    isResolved={isResolved}
                                    resolutionNotes={resolutionNotes}
                                    submitAnotherResponse={submitAnotherResponse}
                                />
                            }

                        </Col>
                    </Row>
                </>}


            {errorLogs.length > 0 && !areAllErrorsClosed &&
                <div className="d-flex justify-content-end mt-5">
                    <CloseAllErrorsButton onClick={closeAllErrors} isDisabled={allErrorsClosed} />
                </div>
            }


            {isCloseAllErrors &&
                <CloseAllErrorsSection
                    closeAllErrorsNote={closeAllErrorsNote}
                    setCloseAllErrorsNote={setCloseAllErrorsNote}
                    setIsCloseAllErrors={setIsCloseAllErrors}
                    handleCloseAllErrors={handleCloseAllErrors}
                    loadingCloseAllErrors={loadingCloseAllErrors}
                />
            }

            {allErrorsClosed &&
                <div className="p-3 greenBadge"><i className="bi bi-check" /> All errors closed</div>
            }
        </div>
    )
}