
interface Props {
    onClick: () => void;
    isDisabled: boolean;
}

export default function CloseAllErrorsButton({ onClick, isDisabled }: Props) {
    return (
        <button className="btn btn-success" data-testid="closeAllErrorsBtn" onClick={onClick} disabled={isDisabled}>Close all errors</button>
    )
}