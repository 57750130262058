import { ContactMethodEnum } from "../../typeDeclarations/Enums";

interface Props {
    contactMethod: ContactMethodEnum;
    contactAddress: string;
    handleClick: any;
    isSelected: boolean;
}

export default function ContactButton({ contactMethod, contactAddress, handleClick, isSelected }: Props) {
    return (
        <>

            <button onClick={handleClick} className={isSelected ? "btn btn-primary" : "btn btn-light border"} data-testid="contactBtn">

                {contactMethod === ContactMethodEnum.EMAIL &&
                    <>
                        <i className="bi bi-envelope font-large" data-testid="contactBtnIcon" /> <span data-testid="contactMethod">{contactMethod}</span>

                        <a href={`mailto:${contactAddress}`}>
                            <span style={{ display: "none" }}>a</span>
                        </a>

                    </>
                }

                {contactMethod === ContactMethodEnum.PHONE &&
                    <>
                        <i className="bi bi-telephone font-large" data-testid="contactBtnIcon" /> <span data-testid="contactMethod">{contactMethod}</span>
                        <a href={`tel:${contactAddress}`}>
                            <span style={{ display: "none" }}>a</span>
                        </a>
                    </>
                }

                {contactMethod === ContactMethodEnum.FAX &&
                    <>
                        <i className="bi bi-printer font-large" data-testid="contactBtnIcon" /> <span data-testid="contactMethod">{contactMethod}</span>
                        <a href={`tel:${contactAddress}`}>
                            <span style={{ display: "none" }}>a</span>
                        </a>
                    </>
                }

                {contactMethod === ContactMethodEnum.PARCEL &&
                    <>
                        <i className="bi bi-box-seam font-large"data-testid="contactBtnIcon"  /> <span data-testid="contactMethod">{contactMethod}</span>
                        <a href={`tel:${contactAddress}`}>
                            <span style={{ display: "none" }}>a</span>
                        </a>
                    </>
                }



            </button>
        </>
    )
}