import { ContactMethodEnum, SentOrReceivedEnum } from "../typeDeclarations/Enums";
import { DetachmentData, ErrorResponse, ParentData, Provider } from "../typeDeclarations/interfaces";

export const simulateNetworkRequest = () => {
    return new Promise((resolve) => setTimeout(resolve, 2000));
}

export const formatContactEntityDetails = (selectedContactEntity: string, applicantCaseData: any, vendorData: Provider, detachmentData: DetachmentData, parentData: ParentData) => {

    let contactEntityInfoDetails = "";


    if (selectedContactEntity === "applicant") {
        let { lastName, firstName } = applicantCaseData.applicant;
        contactEntityInfoDetails = `${lastName}, ${firstName}`;
    } else if (selectedContactEntity === "vendor") {
        let { vendorCode, name, examName, streetAddress, city, state, postalcode } = vendorData;
        contactEntityInfoDetails = `vendor code: ${vendorCode} - ${name} - ${examName} - address: ${streetAddress} ${city} ${state} ${postalcode}`
    } else if (selectedContactEntity === "detachment") {
        let { unitName, unitNumber, address, city, state, zipCode } = detachmentData;
        contactEntityInfoDetails = `detachment: ${unitName} - unit no. ${unitNumber} - address: ${address} ${city} ${state} ${zipCode}`
    } else if (selectedContactEntity === "parent") {
        let { parentFirstName, parentLastName } = parentData;
        contactEntityInfoDetails = `${parentLastName}, ${parentFirstName}`
    }

    return contactEntityInfoDetails;
}


export const formatErrorObject = (
    selectedContactEntity: string,
    selectedMethod: ContactMethodEnum | string,
    sentOrReceived: SentOrReceivedEnum | string,
    isResolved: string,
    contactEntityInfoDetails: string,
    chosenFinalMsg: string,
    resolutionNotes: string,
    errorLogId: number,
    userName: string
) => {

    let isSent = sentOrReceived === SentOrReceivedEnum.SENT;
    let errorResponse = isResolved === "true" ? "Contact has completed this issue and error is closed" : `${chosenFinalMsg}`;

    let note = isSent ? `contacted ${selectedContactEntity} via ${selectedMethod} - ${contactEntityInfoDetails}. ${resolutionNotes}` : `received message via ${selectedMethod} from ${contactEntityInfoDetails}. ${resolutionNotes}`;


    let errorObject: ErrorResponse = {
        "errorResponse": errorResponse,
        "daysCaseClosureRequired": 0,
        "userNameResponder": userName,
        "dateResponse": new Date().toISOString(),
        "hoursFollowUpRequired": 0,
        "errorLogId": errorLogId,
        "note": note
    }

    // console.log("errorObject: ", errorObject);
    return errorObject;
}

export const determineProvider = (providers: Provider[], vendorCode: number) => {
    // find provider that matches the vendorCode from url params
    let found = providers.find((provider: Provider) => provider.vendorCode === vendorCode);
    return found;
}

export const handleFormatDate = (date: Date) => {
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    return `${mm}/${dd}/${yyyy}`;
}
