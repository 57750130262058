import { SentOrReceivedEnum } from "../../typeDeclarations/Enums";
import { SelectContactMethodFunctionInterface } from "../HistoricalErrorsSection/HistoricalErrorsSection";
import { DetachmentData } from '../../typeDeclarations/interfaces';
import SelectContactCard from "../SelectContactCard/SelectContactCard";
import SelectContactRadioButton from "../SelectContactRadioButton/SelectContactRadioButton";


export interface SelectFunctionInterface {
    (arg: string, arg2: HTMLElement): void;
}


interface Props {
    selectedContactEntity: string;
    setSelectedContactEntity: (entity: string) => void;
    applicantCaseData: any;
    vendorData: any;
    parentData: any;
    detachmentData: DetachmentData;

    NextButton: any;
    chosenErrorLog: any;

    CancelSubmitButton: any;
    selectContactMethod: SelectContactMethodFunctionInterface;
    clearSelectedMethod: () => void;
    selectedMethod: string;
    isCSP: boolean;
    sentOrReceived: string | SentOrReceivedEnum;
    isParentDataAvailable: boolean;
}

export default function StepZero({
    selectedContactEntity,
    setSelectedContactEntity,
    applicantCaseData,
    vendorData,
    parentData,
    detachmentData,

    NextButton,
    chosenErrorLog,

    CancelSubmitButton,
    selectContactMethod,
    clearSelectedMethod,
    selectedMethod,
    isCSP,
    sentOrReceived,
    isParentDataAvailable

}: Props) {


    // Select the contact entity (applicant, vendor, parent, detachment)
    const selectContactEntity = (entity: string) => {
        if (entity !== selectedContactEntity) {
            setSelectedContactEntity(entity);
            clearSelectedMethod();
        }
    }




    return (
        <div className="mt-2">
            <h6>Select contact</h6>

            {/* <div className="p-3 bg-info">
                <p className="mb-0">selectedMethod: {selectedMethod}</p>
                <p className="mb-0">sentOrReceived: {sentOrReceived}</p>
                <p className="mb-0">selectedContactEntity: {selectedContactEntity}</p>
            </div> */}


            {/* Applicant */}
            {/* ========================================================= */}
            <SelectContactRadioButton isChecked={selectedContactEntity === "applicant"} onChange={() => selectContactEntity("applicant")} isDisabled={!chosenErrorLog} />
            <label className="me-2">Applicant</label>

            <SelectContactCard
                selectedContactEntity={selectedContactEntity}

                sentOrReceived={sentOrReceived}
                handleClick={() => selectContactEntity("applicant")}
                isSelected={selectedContactEntity === "applicant"}
                selectContactMethod={selectContactMethod}
                selectedMethod={selectedMethod}

                applicantCaseData={applicantCaseData}

                contactEntity="applicant"
            />
            {/* ========================================================= */}
            {/* Vendor */}
            {/* ========================================================= */}
            <SelectContactRadioButton isChecked={selectedContactEntity === "vendor"} onChange={() => selectContactEntity("vendor")} isDisabled={!chosenErrorLog} />
            <label className="me-2">Vendor</label>

            <SelectContactCard
                selectedContactEntity={selectedContactEntity}

                sentOrReceived={sentOrReceived}
                handleClick={() => selectContactEntity("vendor")}
                isSelected={selectedContactEntity === "vendor"}
                selectContactMethod={selectContactMethod}
                selectedMethod={selectedMethod}

                vendorData={vendorData}
                contactEntity="vendor"
            />


            {/* ========================================================= */}
            {/* Parent */}
            {/* ========================================================= */}

            <SelectContactRadioButton
                isChecked={selectedContactEntity === "parent"}
                onChange={() => {
                    isParentDataAvailable && selectContactEntity("parent")
                }} isDisabled={!chosenErrorLog || !isParentDataAvailable}
            />
            <label className="me-2">Parent</label>


            <SelectContactCard
                selectedContactEntity={selectedContactEntity}

                sentOrReceived={sentOrReceived}
                handleClick={() => isParentDataAvailable && selectContactEntity("parent")}
                isSelected={selectedContactEntity === "parent"}
                isDisabled={!isParentDataAvailable}
                selectContactMethod={selectContactMethod}
                selectedMethod={selectedMethod}

                parentData={parentData}
                isParentDataAvailable={isParentDataAvailable}
                contactEntity="parent"

            />


            {/* ========================================================= */}
            {/* Detachment */}
            {/* ========================================================= */}

            {isCSP && <>

                <SelectContactRadioButton isChecked={selectedContactEntity === "detachment"} onChange={() => selectContactEntity("detachment")} isDisabled={!chosenErrorLog} />
                <label className="me-2">Detachment</label>

                <SelectContactCard
                    selectedContactEntity={selectedContactEntity}

                    sentOrReceived={sentOrReceived}
                    handleClick={() => selectContactEntity("detachment")}
                    isSelected={selectedContactEntity === "detachment"}
                    selectContactMethod={selectContactMethod}
                    selectedMethod={selectedMethod}

                    detachmentData={detachmentData}
                    contactEntity="detachment"
                />

            </>}
            {/* ========================================================= */}




            <div className="d-flex justify-content-between mt-2">
                <div style={chosenErrorLog ? { visibility: "visible" } : { visibility: "hidden" }}>
                    <CancelSubmitButton />
                </div>
                <NextButton isDisabled={!chosenErrorLog || !selectedContactEntity || !selectedMethod} />
            </div>
        </div>

    )
}