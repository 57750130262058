export enum CloseStatusEnum {
    CLOSED = "closed",
    OPEN = "open"
}

export enum ContactEntityEnum {
    APPLICANT = "applicant",
    VENDOR = "vendor",
    PARENT = "parent",
    DETACHMENT = "detachment"
}

export enum ContactMethodEnum {
    PHONE = "phone",
    EMAIL = "email",
    FAX = "fax",
    PARCEL = "parcel"
}

export enum SentOrReceivedEnum {
    SENT = "sent",
    RECEIVED = "received"
}