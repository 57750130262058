import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
    onClick: () => null | void;
    errorLog: any;
    disabled: boolean;
}

export default function ErrorLogTableTitleRow({ onClick, errorLog, disabled }: Props) {

    
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {errorLog.errorType.description}
        </Tooltip>
    );


    return (
        <>
            <span onClick={onClick} className={disabled ? "" : "pointer"} data-testid="errorLogTableTitleRow">
                {errorLog.errorType.legacyDocument} - {errorLog.errorType.itemNumber}. {errorLog.errorType.requirement}
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <button className="btn btn-sm btn py-0 px-0 ms-1"><i className="bi bi-info-circle-fill text-muted" /></button>
                </OverlayTrigger>
            </span>
        </>
    )
}