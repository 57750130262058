import React from 'react';

interface Props {
    isChecked: boolean;
    onChange: (e: string) => void;
    isDisabled: boolean;
}

export default function SelectContactRadioButton({ isChecked, onChange, isDisabled }: Props) {
    return (
        <>
            <input type="radio" className="me-1 form-check-input" name="followUpWith" data-testid="selectContactRadioButton" checked={isChecked} onChange={(e) => onChange("vendor")} disabled={isDisabled} />
        </>
    )
}