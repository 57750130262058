import LoadingSpinnerText from "../LoadingSpinnerText/LoadingSpinnerText";

interface Props {
    SelectedEntitySection: any;
    isResolved: string;
    BackButton: any;
    isSubmitted: boolean;
    isSubmitting: boolean;
    resolutionNotes: string;
    setResolutionNotes: (notes: string) => void;
    submitErrorResponse: any;
    DisplayCannedContactMsgs: any;
    submitAnotherResponse: () => void;
    chosenFinalMsg: string;
    submitError: boolean;
}

export default function StepTwo({
    SelectedEntitySection,
    isResolved,
    BackButton,
    isSubmitted,
    isSubmitting,
    resolutionNotes,
    setResolutionNotes,
    submitErrorResponse,
    DisplayCannedContactMsgs,
    submitAnotherResponse,
    chosenFinalMsg,
    submitError

}: Props) {
    return (
        <div>
            <div className="mb-2">
                <SelectedEntitySection />
            </div>


            {isSubmitted &&
                <div className="p-3 greenBadge mt-2">
                    <i className="bi bi-check" /> Response successfully submitted
                </div>
            }

            {!isSubmitted &&
                <>
                    <h6>Is the error resolved?</h6>
                    <div className={isResolved === "false" ? "lightGreyBadge ps-2" : isResolved === "true" ? "greenBadge ps-2" : "lightCardNoHover"}>
                        {isResolved === "true" ? <span><i className="bi bi-check" /> Resolved</span> : <span><i className="bi bi-x" /> Unresolved</span>}
                    </div>

                    {isResolved === "true" &&
                        <>
                            <div className="my-2">
                                <h6>Choose a message:</h6>
                            </div>

                            <label>Enter note (optional):</label>
                            <textarea className="form-control" value={resolutionNotes} onChange={e => setResolutionNotes(e.target.value)} disabled={isSubmitting} />
                        </>
                    }

                    {isResolved === "false" &&
                        <>
                            <div className="my-2">
                                <DisplayCannedContactMsgs />
                            </div>

                            <label>Enter a note:</label>
                            <textarea className="form-control" value={resolutionNotes} onChange={e => setResolutionNotes(e.target.value)} disabled={isSubmitting} />
                        </>
                    }
                </>
            }

            <div className="d-flex justify-content-between mt-2">
                <span style={isSubmitted ? { visibility: "hidden" } : { visibility: "visible" }}>
                    <BackButton isDisabled={isSubmitting} />
                </span>

                {isSubmitted ?
                    <button onClick={submitAnotherResponse} className="btn btn-primary">Submit another response</button>
                    :
                    <button onClick={submitErrorResponse} className="btn btn-primary" disabled={isSubmitting || (isResolved === "false" && !chosenFinalMsg)}>
                        {isSubmitting ? <LoadingSpinnerText loadingText='submitting' spinnerColor='light' /> : "Submit error response"}
                    </button>
                }
            </div>

            {submitError && <div className="mt-2 p-3 redBadge">Error submitting response</div>}

        </div>
    )
}