import LoadingSpinnerText from "../LoadingSpinnerText/LoadingSpinnerText";

interface Props {
    isSubmitted: boolean;
    submitErrorResponse: () => void;
    isSubmitting: boolean;
    isResolved: string;
    resolutionNotes: string;
    submitAnotherResponse: () => void;
}

export default function StepThree({
    isSubmitted,
    submitErrorResponse,
    isSubmitting,
    isResolved,
    resolutionNotes,
    submitAnotherResponse
 }: Props) {
     const idk = true;
    return (
        <div className="d-flex justify-content-end mt-2">
            {!isSubmitted ?
                <button onClick={submitErrorResponse} className="btn btn-primary" disabled={isSubmitting || (isResolved === "false" && !resolutionNotes)}>
                    {idk ? <LoadingSpinnerText loadingText='submitting' spinnerColor='light' /> : "Submit error response"}
                </button>
                :
                <button onClick={submitAnotherResponse} className="btn btn-primary">Submit another response</button>
            }
        </div>
    )
}