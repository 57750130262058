import axios from "axios";
import quickErrorOptions from '../data/errorOptions';
import { ErrorLog, ErrorResponse } from '../typeDeclarations/interfaces';


interface Notes {
    notes: string;
}


export interface VerifyGuidReqBody {
    guid: string;
    idValue: string;
}


const API = {

    // testConnection: function () {
    //     return axios.get(`${backendApiUrl}/test-connection`)
    // },

    getQuickErrorOptions: function () {
        return quickErrorOptions;
    },


    testBackendLogin: function (backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/login`)
    },


    // By doc type 
    getDocumentErrors: function (docType: string, backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/document-errors`, { params: { docType } })
    },


    // Error logs
    getErrorLogs: function (ApplicantId_ErrorsLogged: number, backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/error-logs`, { params: { ApplicantId_ErrorsLogged } })
    },


    // Create a new error log
    createNewError: function (newError: ErrorLog, backendApiUrl: string) {
        return axios.post(`${backendApiUrl}/error-logs`, newError)
    },

    createErrorResponse: function (newResponse: ErrorResponse, backendApiUrl: string) {
        return axios.post(`${backendApiUrl}/error-log-response`, newResponse)
    },

    getApplicantData: function (ApplicantId: number, backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/basic/applicant`, { params: { ApplicantId } });
    },
    
    closeAllErrors: function (backendApiUrl: string, notes: Notes, caseId: number) {
        return axios.post(`${backendApiUrl}/errors/close-all`, notes, { params: { caseId } });
    },

    closeError: function (backendApiUrl: string, errorsToBeClosed: any, caseId: number) {
        return axios.post(`${backendApiUrl}/errors/close/${caseId}`, errorsToBeClosed);
    },

    // getUserByUserName: function (backendApiUrl: string, userName: string) {
    //     return axios.get(`${backendApiUrl}/user/${userName}`)
    // },

    // clear some or all of the keys in the cache
    clearCache: function (backendApiUrl: string, cacheKeys: string[]) {
        return axios.post(`${backendApiUrl}/cache/clear`, cacheKeys)
    }, 

    verifyGuid: function (backendApiUrl: string, reqBody: VerifyGuidReqBody) {
        return axios.post(`${backendApiUrl}/verification/guid`, reqBody)
    },



    // ==============================
    // Azure functions
    // ==============================

    getSettings: function () {
        return axios.get(`/api/settings`);
    },
    getEnvironment: function () {
        return axios.get(`/api/environment`);
    }

}



export default API;