import { ChooseErrorLogFunction, ChooseMessageFunction } from "../../typeDeclarations/interfaces";
import ErrorLogTable from "../ErrorLogTable/ErrorLogTable";

interface Props {
    errorLogs: any;
    chooseMessage: ChooseMessageFunction;
    chooseErrorLog: ChooseErrorLogFunction;
    chosenErrorLog: any;
}

export default function ErrorLogTables({ errorLogs, chooseMessage, chooseErrorLog, chosenErrorLog }: Props) {
    return (
        <>

            <div className="my-2">


                {errorLogs.length === 0 ?
                    <div className="lightGreyBadge p-3" data-testid="noErrorsLoggedDiv">No errors logged yet</div>
                    :
                    <>
                        {errorLogs.map((errorLog: any) => (
                            <ErrorLogTable
                                key={errorLog.id}
                                errorLog={errorLog}
                                chooseMessage={chooseMessage}
                                chosenErrorLog={chosenErrorLog}
                                chooseErrorLog={chooseErrorLog}
                            />
                        ))}
                    </>
                }


            </div>
        </>
    )
}