import { SearchResultInterface } from "../../typeDeclarations/interfaces";


interface Props {
    result: SearchResultInterface;
    searchText: string;
    selectError: (a: any) => void;
    clear: () => void;
}

export default function SearchResult({ result, searchText, selectError, clear }: Props) {
    let description = result.description.toLowerCase();
    let index = description.indexOf(searchText);

    let inner = "";
    let outer = "";

    const highlight = "blueBadge fw-bold";


    // 1) for start
    if (index === 0) {
        inner = description.slice(index, searchText.length);
        outer = description.slice(index + searchText.length);

        return (
            <div className="card mb-2 shadow-sm pointer" onClick={() => { selectError(result); clear(); }} data-testid="resultDiv">
                <span>
                    {result.itemNumber}. <span><span className={highlight}>{inner}</span>{outer}</span>
                </span>
            </div>
        )
    }

    // 2) for end
    else if (index === description.length - searchText.length) {

        inner = description.slice(0, index); // from 0 to the start of the index
        outer = description.slice(index, index + searchText.length);

        return (
            <div className="card mb-2 shadow-sm pointer" onClick={() => { selectError(result); clear(); }} data-testid="resultDiv">
                <span>
                    {result.itemNumber}. <span>{inner}<span className={highlight}>{outer}</span></span>
                </span>
            </div>
        )
    }

    // 3) for inside
    else {
        // get start index of inner portion
        let start = description.slice(0, index);
        // get middle 
        let middle = description.slice(index, index + searchText.length);
        // get index of the end of the inner portion
        let end = description.slice(index + searchText.length);

        return (
            <div className="card mb-2 shadow-sm pointer" onClick={() => { selectError(result); clear(); }} data-testid="resultDiv">
                <span>
                    {result.itemNumber}. {start}<span className={highlight}>{middle}</span>{end}
                </span>
            </div>
        )
    }

}