import React from 'react'
import LoadingSpinnerText from '../LoadingSpinnerText/LoadingSpinnerText';

interface Props {
    closeAllErrorsNote: string;
    setCloseAllErrorsNote: (note: string) => void;
    setIsCloseAllErrors: (isCloseAllErrors: boolean) => void;
    handleCloseAllErrors: () => void;
    loadingCloseAllErrors: boolean;
}

export default function CloseAllErrorsSection({
    closeAllErrorsNote,
    setCloseAllErrorsNote,
    setIsCloseAllErrors,
    handleCloseAllErrors,
    loadingCloseAllErrors

}: Props) {

    const cancel = () => {
        setIsCloseAllErrors(false); setCloseAllErrorsNote("");
    }

    const submitCloseAllErrors = () => {
        setIsCloseAllErrors(false); handleCloseAllErrors(); 
    }




    return (
        <div>
            <p>Are you sure you want to close all errors?</p>
            <label>Please enter a confirmation note (required)</label>
            <textarea value={closeAllErrorsNote} className="form-control" onChange={e => setCloseAllErrorsNote(e.target.value)} placeholder="required" disabled={loadingCloseAllErrors} />

            <div className="mt-1">
                {loadingCloseAllErrors ?
                    <button className="btn btn-primary"><LoadingSpinnerText spinnerColor='light' /></button>
                    :
                    <button onClick={submitCloseAllErrors} className="btn btn-primary" disabled={!closeAllErrorsNote}>yes</button>
                }{' '}
                <button onClick={cancel} className="btn btn-outline-primary" disabled={loadingCloseAllErrors}>cancel</button>
            </div>
        </div>
    )
}