
interface Props {
    isComplete: boolean;
    stepNumber: string;
    stepName: string;
}

export default function ContactStep({ isComplete, stepNumber, stepName }: Props) {
    return (
        <div>
            <div className={isComplete ? "text-success" : "text-secondary"} data-testid="stepName">{stepName}</div>
            <div className={isComplete ? "stepComplete" : "step"} data-testid="stepNumber">{stepNumber}</div>
        </div>
    )
}