
interface Props {
    toggleShowRow: () => void;
    showRow: boolean;
}

export default function ToggleRowShowButton({ toggleShowRow, showRow }: Props) {
    return (
        <button className="btn btn-sm py-0 border-secondary" data-testid="toggleRowShowBtn" onClick={toggleShowRow}><i data-testid="toggleRowShowBtnIcon" className={showRow ? "bi bi-chevron-down" : "bi bi-chevron-right"} /></button>
    )
}